import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import * as React from 'react'
import { useState } from 'react'
import { Link as Scroll } from 'react-scroll'
import styles from './default-nav.module.scss'

const DefaultNav: React.FC = () => {
  const [isShownDropDown, setIsShownDropDown] = useState(false)
  const location = useLocation()

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li>
          <Link to="/about">Cognition Cafeってなに？</Link>
        </li>
        <li>
          <Link to="/video">Cognition Cafe Reserve</Link>
        </li>
        <li>
          <a onClick={() => setIsShownDropDown(!isShownDropDown)}>
            コグカフェ便り
          </a>
          {isShownDropDown && (
            <div className={styles.dropDown}>
              {location.pathname === '/' && (
                <>
                  <Scroll
                    to="books"
                    smooth={true}
                    offset={-70}
                    onClick={() => setIsShownDropDown(!isShownDropDown)}
                  >
                    書籍
                  </Scroll>
                  <Scroll
                    to="documents"
                    smooth={true}
                    offset={-70}
                    onClick={() => setIsShownDropDown(!isShownDropDown)}
                  >
                    文献
                  </Scroll>
                </>
              )}
              {location.pathname !== '/' && (
                <>
                  <Link
                    to="/#books"
                    onClick={() => setIsShownDropDown(!isShownDropDown)}
                  >
                    書籍
                  </Link>
                  <Link
                    to="/#documents"
                    onClick={() => setIsShownDropDown(!isShownDropDown)}
                  >
                    文献
                  </Link>
                </>
              )}
            </div>
          )}
        </li>
        <li>
          <Link to="/grandMenu">Grand Menu</Link>
        </li>
        <li>
          <button className={styles.headerButton}>
            <a
              href="https://questant.jp/q/AD2V217R"
              target="_blank"
              rel="noreferrer"
            >
              ご意見お聞かせください
            </a>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default DefaultNav
