import { useLocation } from '@reach/router'
import clsx from 'clsx'
import { Link } from 'gatsby'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import '@/components/general/slideMenu.scss'
import { Link as Scroll } from 'react-scroll'
import crossIcon from '@/images/icon-cross.svg'

const SlideMenu: React.FC = () => {
  const location = useLocation()
  const [small, setSmall] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setSmall(window.pageYOffset > 50))
    }
  }, [])

  return (
    <div className={clsx('slide-menu-wrap', small ? 'slide-wrap-small' : '')}>
      <Menu
        isOpen={isOpen}
        width={'100%'}
        customCrossIcon={<img src={crossIcon} />}
        right
      >
        <div className="menu-wrap">
          <ul className="menu-list">
            <li>
              <Link to="/about">Cognition Cafeってなに？</Link>
            </li>
            <li>
              <Link to="/video">Cognition Cafe Reserve</Link>
            </li>
            {location.pathname === '/' && (
              <>
                <li>
                  <Scroll
                    to="books"
                    smooth={true}
                    offset={-70}
                    onClick={handleClose}
                  >
                    コグカフェ便り：書籍
                  </Scroll>
                </li>
                <li>
                  <Scroll
                    to="documents"
                    smooth={true}
                    offset={-70}
                    onClick={handleClose}
                  >
                    コグカフェ便り：文献
                  </Scroll>
                </li>
              </>
            )}
            {location.pathname !== '/' && (
              <>
                <li>
                  <Link to="/#books">コグカフェ便り：書籍</Link>
                </li>
                <li>
                  <Link to="/#documents">コグカフェ便り：文献</Link>
                </li>
              </>
            )}

            <li>
              <Link to="/grandMenu">Grand Menu</Link>
            </li>
          </ul>
          <div className="survey">
            <a
              href="https://questant.jp/q/AD2V217R"
              target="_blank"
              rel="noreferrer"
            >
              <button className="surveyButton">ご意見お聞かせください</button>
            </a>
          </div>
        </div>
      </Menu>
    </div>
  )
}

export default SlideMenu
