import { useStaticQuery, graphql } from 'gatsby'
import * as React from 'react'
import SlideMenu from '../general/slideMenu'
import Footer from './footer'
import Header from './header'
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
      <div>
        <SlideMenu />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
