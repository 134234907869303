import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link as Scroll, animateScroll } from 'react-scroll'
import styles from './footer.module.scss'
import carenetLogo from '@/images/carenet-logo.svg'
import footerBg from '@/images/footer-bg.svg'

const Footer: React.FC = () => {
  const location = useLocation()

  return (
    <footer className={styles.footer}>
      <div className={styles.footerBg}>
        <img src={footerBg} alt="shape" />
      </div>
      <div className={styles.footerInner}>
        <div className={styles.footerCafeLogo}>
          <StaticImage
            src="../../images/cafe-logo-black-white.png"
            width={105}
            quality={60}
            formats={['auto', 'webp', 'avif']}
            alt="Cognition Cafe"
          />
          <button className={styles.footerButtonSp}>
            <a
              href="https://questant.jp/q/AD2V217R"
              target="_blank"
              rel="noreferrer"
            >
              ご意見お聞かせください
            </a>
          </button>
        </div>
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li>
              <Link to="/about">Cognition Cafeってなに？</Link>
            </li>
            <li>
              <Link to="/video">Cognition Cafe Reserve</Link>
            </li>
            {location.pathname === '/' && (
              <>
                <li>
                  <Scroll to="books" smooth={true} offset={-70}>
                    コグカフェ便り：書籍
                  </Scroll>
                </li>
                <li>
                  <Scroll to="documents" smooth={true} offset={-70}>
                    コグカフェ便り：文献
                  </Scroll>
                </li>
              </>
            )}
            {location.pathname !== '/' && (
              <>
                <li>
                  <Link to="/#books">コグカフェ便り：書籍</Link>
                </li>
                <li>
                  <Link to="/#documents">コグカフェ便り：文献</Link>
                </li>
              </>
            )}
          </ul>

          <ul className={styles.navListSmall}>
            <li>
              <a href="/grandMenu" className={styles.navGrandMenu}>
                Grand Menu
              </a>
            </li>
            <li>
              <a
                href="https://contact.carenet.com/select?id1=8&id2=D"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>
            </li>
            <li>
              <Link to="/terms">利用規約</Link>
            </li>
            <li>
              <a
                href="https://www.carenet.com/info/personal.html"
                target="_blank"
                rel="noreferrer"
              >
                個人情報保護
              </a>
            </li>
          </ul>
        </nav>
        <button className={styles.footerButton}>
          <a
            href="https://questant.jp/q/AD2V217R"
            target="_blank"
            rel="noreferrer"
          >
            ご意見お聞かせください
          </a>
        </button>
      </div>
      <div className={styles.footerCloser}>
        <div className={styles.carenetLogo}>
          <a href="https://www.carenet.com/" target="_blank" rel="noreferrer">
            <img src={carenetLogo} />
          </a>
        </div>
        <span>Copyright(c)2022 CareNet,Inc All Rights Reserved.</span>
      </div>
      <div className={styles.buttonArea}>
        <button
          className={styles.toTopButton}
          onClick={animateScroll.scrollToTop}
        >
          <svg
            className={styles.scrollPC}
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#282828" />
            <path
              d="M22.6848 16.722L12.3348 27.5846C12.1621 27.7659 12.0657 28.0067 12.0657 28.2571C12.0657 28.5075 12.1621 28.7483 12.3348 28.9295L12.3465 28.9412C12.4303 29.0294 12.5311 29.0996 12.6429 29.1476C12.7546 29.1956 12.875 29.2203 12.9966 29.2203C13.1182 29.2203 13.2385 29.1956 13.3503 29.1476C13.462 29.0996 13.5629 29.0294 13.6466 28.9412L23.3924 18.7121L33.1342 28.9412C33.218 29.0294 33.3188 29.0996 33.4306 29.1476C33.5423 29.1956 33.6627 29.2203 33.7843 29.2203C33.9059 29.2203 34.0262 29.1956 34.138 29.1476C34.2497 29.0996 34.3506 29.0294 34.4343 28.9412L34.446 28.9295C34.6188 28.7483 34.7152 28.5075 34.7152 28.2571C34.7152 28.0067 34.6188 27.7659 34.446 27.5846L24.096 16.722C24.005 16.6265 23.8955 16.5504 23.7743 16.4984C23.653 16.4465 23.5224 16.4197 23.3904 16.4197C23.2585 16.4197 23.1279 16.4465 23.0066 16.4984C22.8853 16.5504 22.7759 16.6265 22.6848 16.722Z"
              fill="white"
            />
          </svg>

          <svg
            className={styles.scrollSP}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="#282828" />
            <path
              d="M19.7236 14.5339L10.7236 23.9796C10.5733 24.1372 10.4895 24.3466 10.4895 24.5644C10.4895 24.7821 10.5733 24.9915 10.7236 25.1491L10.7337 25.1593C10.8066 25.236 10.8942 25.297 10.9914 25.3387C11.0886 25.3805 11.1932 25.402 11.299 25.402C11.4047 25.402 11.5094 25.3805 11.6066 25.3387C11.7037 25.297 11.7914 25.236 11.8642 25.1593L20.3388 16.2644L28.81 25.1593C28.8828 25.236 28.9705 25.297 29.0677 25.3387C29.1649 25.3805 29.2695 25.402 29.3753 25.402C29.481 25.402 29.5857 25.3805 29.6828 25.3387C29.78 25.297 29.8677 25.236 29.9405 25.1593L29.9507 25.1491C30.1009 24.9915 30.1847 24.7821 30.1847 24.5644C30.1847 24.3466 30.1009 24.1372 29.9507 23.9796L20.9507 14.5339C20.8715 14.4508 20.7763 14.3847 20.6709 14.3395C20.5654 14.2943 20.4519 14.271 20.3371 14.271C20.2224 14.271 20.1088 14.2943 20.0034 14.3395C19.8979 14.3847 19.8027 14.4508 19.7236 14.5339Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </footer>
  )
}

export default Footer
