import clsx from 'clsx'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useEffect, useState } from 'react'
import DefaultNav from './default-nav'
import styles from './header.module.scss'

type Props = {
  siteTitle: string
}

const Header: React.FC<Props> = () => {
  const [small, setSmall] = useState(false)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    const scrollHandler = () => {
      setSmall(window.pageYOffset > 50)
    }

    if (typeof window !== 'undefined') {
      scrollHandler()
      window.addEventListener('scroll', scrollHandler)
    }

    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [])
  return (
    <>
      {isLoaded ? (
        <div
          className={clsx(
            styles.headerWrap,
            small ? styles.smallHeaderWrap : ''
          )}
        >
          <header
            className={clsx(styles.header, small ? styles.smallHeader : '')}
          >
            <div
              className={clsx(styles.headerLogo, small ? styles.smallLogo : '')}
            >
              <h1>
                <Link to={'/'} className={styles.logoPc}>
                  <StaticImage
                    src="../../images/logo@2x.png"
                    width={428}
                    quality={80}
                    formats={['auto', 'webp', 'avif']}
                    loading="eager"
                    alt="Cognition Cafe"
                  />
                </Link>
                <Link to={'/'} className={styles.logoSp}>
                  <StaticImage
                    src="../../images/logo-sp.png"
                    width={350}
                    quality={100}
                    objectFit="contain"
                    formats={['auto', 'webp', 'avif']}
                    loading="eager"
                    alt="Cognition Cafe"
                  />
                </Link>
              </h1>
            </div>
            <div
              className={clsx(styles.headerNav, small ? styles.smallNav : '')}
            >
              <DefaultNav />
            </div>
          </header>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default Header
